import React, {Component} from "react";
import {Redirect} from 'react-router-dom';
import {Button, Container, TextField, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import {Add} from "@material-ui/icons"

import {createItemReq} from './../../requests/item';
import {getAllCategoriesReq} from './../../requests/category';

class AddItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newItem: {
                name: '',
                amount: '',
                category_id: 0
            },
            categoriesLoaded: false,
            alertBox: false,
            nameError: false,
            amountError: false,
            categoryError: false,
            categories: [],
            redirect: false
        };
    }

    getAllCategories = async () => {
        try {
            const response = await getAllCategoriesReq();
            if (response.data.data) {
                this.setState({
                    categories: response.data.data
                });
                return response;
            } else {
                this.setState({alertBox: true})
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleItemNameChange = (e) => {
        let newItem = this.state.newItem;
        newItem.name = e.target.value;
        this.setState({
            newItem: newItem
        });
    };

    handleItemAmountChange = (e) => {
        let newItem = this.state.newItem;
        newItem.amount = e.target.value;
        this.setState({
            newItem: newItem
        });
    };

    handleItemCategoryChange = (e) => {
        let newItem = this.state.newItem;
        newItem.category_id = parseInt(e.target.value);
        this.setState({
            newItem: newItem
        });
    };

    handleSubmit = () => {
        let nameError = false;
        let amountError = false;
        let categoryError = false;
        let validationError = false;

        if (this.state.newItem.name.length <= 0) {
            nameError = true;
            validationError = true;
        }

        if (this.state.newItem.amount.length <= 0 && !validationError) {
            amountError = true;
            validationError = true;
        }

        if (this.state.newItem.category_id.length <= 0 && !validationError) {
            categoryError = true;
            validationError = true;
        }

        if (validationError) {
            this.setState({
                nameError: nameError,
                amountError: amountError,
                categoryError: categoryError
            });
            return false;
        } else {
            const createItem = async (item) => {
                try {
                    const response = await createItemReq(item);
                    this.props.closeFunc();
                    this.props.refreshFunc();
                } catch (error) {
                    console.log(error);
                }
            };
            createItem(this.state.newItem);
        }
    };

    redirectToCategories = () => {
        this.setState({redirect: true})
    };

    render() {
        if (!this.state.categoriesLoaded) {
            this.getAllCategories();
            this.setState({
                categoriesLoaded: true
            });
        }

        if(this.state.redirect) {
            return <Redirect to='/categories'/>
        }
        return (
            <>
                <Container maxWidth="xs" className="modal-wrapper">
                    <div className="modal-content">
                        <h2 className="modal-content__title">Add new item</h2>
                        <form className="modal-content__form" autoComplete="off">
                            <div>
                                <TextField required
                                           id="item-name"
                                           label="Item-Name"
                                           className="modal-content__form-input"
                                           value={this.state.newItem.name}
                                           onChange={this.handleItemNameChange}
                                           error={this.state.nameError}
                                           helperText="Please type in the name of your item"/>
                                <TextField required
                                           id="item-amount"
                                           label="Amount of item"
                                           className="modal-content__form-input"
                                           value={this.state.newItem.amount}
                                           onChange={this.handleItemAmountChange}
                                           error={this.state.amountError}
                                           helperText="Please type in the amount of your item"/>
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    label="Choose category"
                                    value={this.state.newItem.category_id}
                                    className="modal-content__form-input"
                                    onChange={this.handleItemCategoryChange}
                                    error={this.state.categoryError}
                                    helperText="Please select your category">
                                    {this.state.categories.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.handleSubmit}
                                    startIcon={<Add/>}
                                >
                                    Add item
                                </Button>
                            </div>
                        </form>
                    </div>
                </Container>
                <Dialog
                    open={this.state.alertBox}
                    width={1}
                    onClose={this.redirectToCategories}
                    aria-labelledby="no-categories"
                    aria-describedby="no-categories"
                >
                    <DialogTitle id="alert-dialog-title">There are no categories!</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="no-categories">
                            There are no categories. You need to create a new category!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.redirectToCategories} color="primary"
                                variant="contained" autoFocus>
                            Create new category
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AddItemModal;