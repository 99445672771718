import axios from 'axios';

// Get all category
export const getAllCategoriesReq = () => axios.get('https://api.shoppinglist.yves-schlimpert.ch/data/api/category/read.php');

// Get single category
export const getSingleCategoryReq = (id) => axios.get('https://api.shoppinglist.yves-schlimpert.ch/data/api/category/read_single.php?id=' + id);

// Create category
export const createCategoryReq = (category) => axios.post('https://api.shoppinglist.yves-schlimpert.ch/data/api/category/create.php', category);

// Udpate category
export const updateCategoryReq = (category) => axios.put('https://api.shoppinglist.yves-schlimpert.ch/data/api/category/update.php', category);

// Delete category
export const deleteCategoryReq = (id) => axios.delete('https://api.shoppinglist.yves-schlimpert.ch/data/api/category/delete.php?id=' + id);