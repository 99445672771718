import React from "react";
import {Link} from "react-router-dom";
import { AppBar, Toolbar, Button, Typography, Container} from "@material-ui/core";

function Navbar() {
    return (
        <AppBar color="primary">
            <Container maxWidth="lg">
                <Toolbar style={{padding : 0}}>
                    <Typography variant="h6" style={{flexGrow : 1}}>
                        <Link to={'/'}>Shoppinglist</Link>
                    </Typography>
                    <Button color="inherit">
                        <Link to={'/categories'}>Categories</Link>
                    </Button>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;