import React, {Component} from "react";
import {Grid, Button, Modal} from "@material-ui/core";
import {Add} from "@material-ui/icons"

import AddCategoryModal from "./../components/modals/AddCategoryModal";
import CategoryList from "./../components/lists/CategoryList";

import {getAllCategoriesReq} from "../requests/category";

class Categories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            categoriesLoaded: false,
            modalOpen: false
        };
    }

    handleAddCategoryModalOpen = () => {
        this.setState({
            modalOpen: true
        });
    };

    handleAddCategoryModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    getAllCategories = async () => {
        try {
            const response = await getAllCategoriesReq();
            this.setState({categories: response.data.data});
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        if (!this.state.categoriesLoaded) {
            this.getAllCategories();
            this.setState({categoriesLoaded: true});
        }
    }

    render() {
        return (
            <>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h1>Categories</h1>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleAddCategoryModalOpen}
                        startIcon={<Add/>}
                    >
                        Add category
                    </Button>
                </Grid>
                <CategoryList categories={this.state.categories} refreshFunc={this.getAllCategories}/>
                <Modal open={this.state.modalOpen} onClose={this.handleAddCategoryModalClose}>
                    <AddCategoryModal closeFunc={this.handleAddCategoryModalClose} refreshFunc={this.getAllCategories}/>
                </Modal>
            </>
        );
    }
}

export default Categories;