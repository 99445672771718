import React, {Component} from 'react';

import {
    ButtonGroup,
    Button,
    Paper,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Modal
} from "@material-ui/core";

import {Edit, Delete} from "@material-ui/icons"

import EditCategoryModal from "../modals/EditCategoryModal";
import {deleteCategoryReq} from "../../requests/category";

class CategoryList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalEditOpen: false,
            currentCategory: {}
        };
    }

    handleEditCategoryModalOpen = (category) => {
        this.setState({
            modalEditOpen: true,
            currentCategory: category
        });
    };

    handleEditCategoryModalClose = () => {
        this.setState({
            modalEditOpen: false
        });
    };

    handleDelete = async (id) => {
        try {
            const response = await deleteCategoryReq(id);
            this.props.refreshFunc();
        } catch (error) {
            console.log(error);
        }
    };

    refreshFuncChildren = () => {
        this.props.refreshFunc();
    };

    render() {
        let rows;
        if (this.props.categories) {
            rows = this.props.categories.map((category) => (
                <TableRow key={category.id}>
                    <TableCell component="th" scope="row">
                        {category.name}
                    </TableCell>
                    <TableCell align="right">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="inherit"
                                className="textless-button"
                                onClick={() => this.handleEditCategoryModalOpen(category)}
                                startIcon={<Edit/>}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                className="textless-button"
                                onClick={() => this.handleDelete(category.id)}
                                startIcon={<Delete/>}
                            />
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            ));
        } else {
            rows = (
                <TableRow>
                    <TableCell component="th" scope="row">
                        No categories available
                    </TableCell>
                </TableRow>
            )
        }

        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal open={this.state.modalEditOpen} onClose={this.handleEditCategoryModalClose}>
                    <EditCategoryModal closeFunc={this.handleEditCategoryModalClose} category={this.state.currentCategory}
                                       refreshFunc={this.refreshFuncChildren}/>
                </Modal>
            </>
        );
    }
}

export default CategoryList;