import React, {Component} from "react";
import {Grid, Button, Modal} from "@material-ui/core";
import {Add} from "@material-ui/icons"

import AddItemModal from "./../components/modals/AddItemModal";
import ItemList from "./../components/lists/ItemList";

import {getAllItemsReq} from "../requests/item";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            itemsLoaded: false,
            modalOpen: false
        };
    }

    handleAddItemModalOpen = () => {
        this.setState({
            modalOpen: true
        });
    };

    handleAddItemModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    getAllItems = async () => {
        try {
            const response = await getAllItemsReq();
            this.setState({items: response.data.data});
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        if (!this.state.itemsLoaded) {
            this.getAllItems();
            this.setState({itemsLoaded: true});
        }
    }

    render() {
        return (
            <>
                <h1>Shoppinglist</h1>
                <hr/>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h2>Things to buy</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleAddItemModalOpen}
                        startIcon={<Add/>}
                    >
                        Add item
                    </Button>
                </Grid>
                <ItemList bought={false} items={this.state.items} refreshFunc={this.getAllItems}/>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h2>Things already bought</h2>
                </Grid>
                <ItemList bought={true} items={this.state.items} refreshFunc={this.getAllItems}/>
                <Modal open={this.state.modalOpen} onClose={this.handleAddItemModalClose}>
                    <AddItemModal closeFunc={this.handleAddItemModalClose} refreshFunc={this.getAllItems}/>
                </Modal>
            </>
        );
    }
}

export default Home;