import React, {Component} from "react";
import {Button, Container, TextField, MenuItem} from "@material-ui/core";
import {Save} from "@material-ui/icons"

import {updateItemReq} from './../../requests/item';
import {getAllCategoriesReq} from './../../requests/category';

class EditItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newItem: {
                id: this.props.item.id,
                name:  this.props.item.name,
                amount:  this.props.item.amount,
                category_id:  this.props.item.category_id,
                bought: this.props.item.bought
            },
            categoriesLoaded: false,
            responseMessage: '',
            nameError: false,
            amountError: false,
            categoryError: false,
            categories: []
        };
    }

    getAllCategories = async () => {
        try {
            const response = await getAllCategoriesReq();
            this.setState({categories: response.data.data});
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    handleItemNameChange = (e) => {
        let newItem = this.state.newItem;
        newItem.name = e.target.value;
        this.setState({
            newItem: newItem
        });
    };

    handleItemAmountChange = (e) => {
        let newItem = this.state.newItem;
        newItem.amount = e.target.value;
        this.setState({
            newItem: newItem
        });
    };

    handleItemCategoryChange = (e) => {
        let newItem = this.state.newItem;
        newItem.category_id = parseInt(e.target.value);
        this.setState({
            newItem: newItem
        });
    };

    handleSubmit = () => {
        let nameError = false;
        let amountError = false;
        let categoryError = false;
        let validationError = false;

        if (this.state.newItem.name.length <= 0) {
            nameError = true;
            validationError = true;
        }

        if (this.state.newItem.amount.length <= 0 && !validationError) {
            amountError = true;
            validationError = true;
        }

        if (this.state.newItem.category_id.length <= 0 && !validationError) {
            categoryError = true;
            validationError = true;
        }

        if (validationError) {
            this.setState({
                nameError: nameError,
                amountError: amountError,
                categoryError: categoryError
            });
            return false;
        } else {
            const updateItem = async (item) => {
                try {
                    const response = await updateItemReq(item);
                    this.props.closeFunc();
                    this.props.refreshFunc();
                } catch (error) {
                    console.log(error);
                }
            };
            updateItem(this.state.newItem);
        }
    };

    render() {
        if(!this.state.categoriesLoaded) {
            this.getAllCategories();
            this.setState({categoriesLoaded: true});
        }
        return (
            <Container maxWidth="xs" className="modal-wrapper">
                <div className="modal-content">
                    <h2 className="modal-content__title">Save item</h2>
                    <form className="modal-content__form" autoComplete="off">
                        <div>
                            <TextField required
                                       id="item-name"
                                       label="Item-Name"
                                       className="modal-content__form-input"
                                       value={this.state.newItem.name}
                                       onChange={this.handleItemNameChange}
                                       error={this.state.nameError}
                                       helperText="Please type in the name of your item"/>
                            <TextField required
                                       id="item-amount"
                                       label="Amount of item"
                                       className="modal-content__form-input"
                                       value={this.state.newItem.amount}
                                       onChange={this.handleItemAmountChange}
                                       error={this.state.amountError}
                                       helperText="Please type in the amount of your item"/>
                            <TextField
                                id="standard-select-currency"
                                select
                                label="Choose category"
                                value={this.state.newItem.category_id}
                                className="modal-content__form-input"
                                onChange={this.handleItemCategoryChange}
                                error={this.state.categoryError}
                                helperText="Please select your category">
                                {this.state.categories.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={this.handleSubmit}
                                startIcon={<Save/>}
                            >
                                Save item
                            </Button>
                        </div>
                    </form>
                </div>
            </Container>
        );
    }
}

export default EditItemModal;