import React, {Component} from 'react';

import {
    ButtonGroup,
    Button,
    Paper,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Modal
} from "@material-ui/core";

import {AddShoppingCart, RemoveShoppingCart, Edit, Delete} from "@material-ui/icons"

import EditItemModal from "../modals/EditItemModal";
import {updateItemReq, deleteItemReq} from "../../requests/item";

class ItemList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalEditOpen: false,
            currentItem: {}
        };
    }

    handleEditItemModalOpen = (item) => {
        this.setState({
            modalEditOpen: true,
            currentItem: item
        });
    };

    handleEditItemModalClose = () => {
        this.setState({
            modalEditOpen: false
        });
    };

    updateItem = async (item) => {
        try {
            const response = await updateItemReq(item);
            this.props.refreshFunc();
        } catch (error) {
            console.log(error);
        }
    };

    deleteItem = async (id) => {
        try {
            const response = await deleteItemReq(id);
            this.props.refreshFunc();
        } catch (error) {
            console.log(error);
        }
    };

    handleBought = (item) => {
        let newItem = item;
        newItem.bought = 1;

        this.updateItem(newItem);
    };

    handleUnbought = (item) => {
        let newItem = item;
        newItem.bought = 0;

        this.updateItem(newItem);
    };

    handleDelete = (item) => {
        let id = parseInt(item.id);
        this.deleteItem(id);
    };

    rowBought = () => {
        let boughtItems;
        if (this.props.items) {
            boughtItems = this.props.items.filter(item => parseInt(item.bought) === 1);
        }
        let returnElements;

        if (this.props.items && boughtItems.length > 0) {
            returnElements = boughtItems.map((item) => (
                <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                        {item.name}
                    </TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.category_name}</TableCell>
                    <TableCell align="right">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="inherit"
                                className="textless-button"
                                onClick={() => this.handleEditItemModalOpen(item)}
                                startIcon={<Edit/>}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className="textless-button"
                                onClick={() => this.handleUnbought(item)}
                                startIcon={<RemoveShoppingCart/>}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                className="textless-button"
                                onClick={() => this.handleDelete(item)}
                                startIcon={<Delete/>}
                            />
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            ));
        } else {
            returnElements = (
                <TableRow>
                    <TableCell component="th" scope="row">
                        No items available
                    </TableCell>
                </TableRow>
            )
        }

        return returnElements;
    };

    rowUnbought = () => {
        let unboughtItems;
        if (this.props.items) {
            unboughtItems = this.props.items.filter(item => parseInt(item.bought) === 0);
        }
        let returnElements;

        if (this.props.items && unboughtItems.length > 0) {
            returnElements = unboughtItems.map((item) => (
                <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                        {item.name}
                    </TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.category_name}</TableCell>
                    <TableCell align="right">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="inherit"
                                className="textless-button"
                                onClick={() => this.handleEditItemModalOpen(item)}
                                startIcon={<Edit/>}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className="textless-button"
                                onClick={() => this.handleBought(item)}
                                startIcon={<AddShoppingCart/>}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                className="textless-button"
                                onClick={() => this.handleDelete(item)}
                                startIcon={<Delete/>}
                            />
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            ));
        } else {
            returnElements = (
                <TableRow>
                    <TableCell component="th" scope="row">
                        No items available
                    </TableCell>
                </TableRow>
            )
        }

        return returnElements;
    };

    refreshFuncChildren = () => {
        this.props.refreshFunc();
    };

    render() {

        let rows;
        if (this.props.bought) {
            rows = this.rowBought();
        } else {
            rows = this.rowUnbought();
        }

        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal open={this.state.modalEditOpen} onClose={this.handleEditItemModalClose}>
                    <EditItemModal closeFunc={this.handleEditItemModalClose} item={this.state.currentItem}
                                   refreshFunc={this.refreshFuncChildren}/>
                </Modal>
            </>
        );
    }
}

export default ItemList;