import React, {Component} from "react";
import {Button, Container, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons"

import {createCategoryReq} from './../../requests/category';

class AddCategoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newCategory: {
                name: ''
            },
            nameError: false
        };
    }

    handleCategoryNameChange = (e) => {
        let newCategory = this.state.newCategory;
        newCategory.name = e.target.value;
        this.setState({
            newCategory: newCategory
        });
    };

    handleSubmit = () => {
        let nameError = true;

        if (this.state.newCategory.name.length > 0) {
            nameError = false;
        }

        if (nameError) {
            this.setState({
                nameError: nameError
            });
            return false;
        } else {
            const createCategory = async (category) => {
                try {
                    const response = await createCategoryReq(category);
                    this.props.closeFunc();
                    this.props.refreshFunc();
                } catch (error) {
                    console.log(error);
                }
            };
            createCategory(this.state.newCategory);
        }
    };

    render() {
        if (!this.state.categoriesLoaded) {
            this.setState({categoriesLoaded: true});
        }
        return (
            <Container maxWidth="xs" className="modal-wrapper">
                <div className="modal-content">
                    <h2 className="modal-content__title">Add new category</h2>
                    <form className="modal-content__form" autoComplete="off">
                        <div>
                            <TextField required
                                       id="category-name"
                                       label="Category-Name"
                                       className="modal-content__form-input"
                                       value={this.state.newCategory.name}
                                       onChange={this.handleCategoryNameChange}
                                       error={this.state.nameError}
                                       helperText="Please type in the name of your category"/>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={this.handleSubmit}
                                startIcon={<Add/>}
                            >
                                Add category
                            </Button>
                        </div>
                    </form>
                </div>
            </Container>
        );
    }
}

export default AddCategoryModal;