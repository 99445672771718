import React from "react";
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import {Container} from "@material-ui/core";

// PAGES
import Categories from './pages/Categories';
import Home from './pages/Home';

import Navbar from './components/layout/Navbar';

function App() {
    return (
        <Router>
            <div className="page-wrapper">
                <Navbar/>
                <Container maxWidth="lg">
                    <div className="page-content">
                        <Switch>
                            <Route path="/categories">
                                <Categories />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    </div>
                </Container>
            </div>
        </Router>
    );
}

export default App;