import axios from 'axios';

// Get all items
export const getAllItemsReq = () => axios.get('https://api.shoppinglist.yves-schlimpert.ch/data/api/item/read.php');

// Get single item
export const getSingleItemReq = (id) => axios.get('https://api.shoppinglist.yves-schlimpert.ch/data/api/item/read_single.php?id=' + id);

// Create item
export const createItemReq = (item) => axios.post('https://api.shoppinglist.yves-schlimpert.ch/data/api/item/create.php', item);

// Udpate item
export const updateItemReq = (item) => axios.put('https://api.shoppinglist.yves-schlimpert.ch/data/api/item/update.php', item);

// Delete item
export const deleteItemReq = (id) => axios.delete('https://api.shoppinglist.yves-schlimpert.ch/data/api/item/delete.php?id=' + id);
